<template>
  <div class="homepage-intro part constrain pt-16 md:pt-40">
    <div class="row-12">
      <div class="md:col-12 mb-8">
        <h1 class="h1" v-html="payload.headline" />
      </div>
      <div class="col-12 sm:col-8 md:col-6">
        <div class="introtext mb-16" v-html="payload.introtext" />
      </div>
      <div class="md:col-12">
        <Button :text="payload.buttontext" :link="payload.buttonlink" class="mt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../utils/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    payload: Object,
  },
  mounted() {
    // console.log(this.payload);
  },
};

</script>

<style lang="scss" scoped>
.introtext {
  &:deep(p) {
    line-height: 1.5;
    @screen lg {
      line-height: 1.75;
    }
  }
}
</style>
